import { get_json } from "./lib.js";
import { fire_event } from "./lib.js";

document.addEventListener("turbolinks:load", function() {

  $(document).on("change", "select[id^=transportation_expense_date]", function (e) {
    document.getElementById("transportation_expense_seq").value = "";
  });

  $(document).on("click", "input[data-transportation-expenses-copy]", function (e) {
    const ids = $("button[data-transportation-expense-id].active").map(function (index, element) {
      return element.getAttribute("data-transportation-expense-id");
    }).toArray().join(",");

    const dates = $("button[data-transportation-expense-date].active").map(function (index, element) {
      return element.getAttribute("data-transportation-expense-date");
    }).toArray().join(",");

    document.querySelector("[name=s]").value = ids;
    document.querySelector("[name=d]").value = dates;
  });

  document.querySelectorAll("#transportation_expense_from, #transportation_expense_to").forEach(function(e) {
    e.addEventListener("change", function(e) {
      const from = document.querySelector("#transportation_expense_from");
      const to = document.querySelector("#transportation_expense_to");
      const both = document.querySelector("#transportation_expense_both");

      var url = new URL(location.origin);
      url.pathname = "/api/transportation_expense_fare.json";
      url.searchParams.append("from", from.value);
      url.searchParams.append("to", to.value);
      url.searchParams.append("both", both.checked);

      get_json(url.toString(), function(data) {
        if (data["id"]) {
          if (data["transportation"] != "taxi" && data["transportation"] != "rental_car") {
            const fare = document.querySelector("#transportation_expense_fare");
            AutoNumeric.set(fare, data["fare"]);
          }

          both.checked = data["both"];
          document.querySelector("#transportation_expense_transportation").value = data["transportation"];
        }
      });
    });
  });

  document.querySelectorAll("#transportation_expense_both").forEach(function(e) {
    e.addEventListener("change", function(e) {
      const fare = document.querySelector("#transportation_expense_fare");
      const both = document.querySelector("#transportation_expense_both");
      const value = parseInt(fare.value.replace(/,/g, ''));

      AutoNumeric.set(fare, both.checked ? value * 2 : value / 2);
    });
  });

});
