// This is a manifest file that'll be compiled into application.js, which will include all the files
// listed below.
//
// Any JavaScript/Coffee file within this directory, lib/assets/javascripts, or any plugin's
// vendor/assets/javascripts directory can be referenced here using a relative path.
//
// It's not advisable to add code directly here, but if you do, it'll appear at the bottom of the
// compiled file. JavaScript code in this file should be added after the last require_* statement.
//
// Read Sprockets README (https://github.com/rails/sprockets#sprockets-directives) for details
// about supported directives.
//
//= require jquery3
//= require popper
//= require bootstrap
//= require rails-ujs
//= require autonumeric
//= require activestorage
//= require turbolinks
//= require_tree .

// autonumeric を機能させるための処理
document.addEventListener("turbolinks:load", function() {

  document.querySelectorAll("[data-autonumeric]").forEach(function(e) {
    var options = JSON.parse(e.dataset["autonumeric"]);
    if (!options.hasOwnProperty("unformatOnSubmit")) {
      options["unformatOnSubmit"] = true
    }
    new AutoNumeric(e, options);
  });

  document.querySelectorAll("[data-action=download]").forEach(function(e) {

    if (e.dataset.downloadUrl.length <= 0) {
      return;
    }

    e.addEventListener("click", function(f) {

      var url = e.dataset.downloadUrl;
      var filename = e.dataset.filename;

      var xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';
      xhr.onreadystatechange = function (e) {
        if (this.readyState == 4 && this.status == 200) {
          var blob = this.response;

          //IE, Edge とその他で処理の切り分け
          if (window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob(blob, filename);
          } else {
            var a = document.createElement("a");
            // IE11 は URL API をサポートしてない
            var url = window.URL;
            a.href = url.createObjectURL(new Blob([blob],
                                                  { type: blob.type }));
            document.body.appendChild(a);
            a.style = "display: none";
            a.download = filename;
            a.click();
          }
        }
      };
      xhr.send();
    })
  });

});
