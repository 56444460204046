import { get_json } from "./lib.js";
import { remove_child_nodes } from "./lib.js";

document.addEventListener("turbolinks:load", function() {
  const isearch_elements = document.querySelectorAll(".dropdown.isearch");

  var isearch_data = null;

  isearch_elements.forEach(function(isearch_element) {
    const isearch_query = isearch_element.querySelector(".isearch-query");
    const isearch_value = isearch_element.querySelector(".isearch-value");
    const isearch_clear = isearch_element.querySelector(".isearch-clear");
    const dropdown_menu = isearch_element.querySelector(".dropdown-menu");
    const init_value = isearch_value ? isearch_value.value : null;

    if (isearch_data == null) {
      isearch_data = false;
      get_json(isearch_element.getAttribute("data-isearch-api"), function(data) {
        isearch_data = data;
      });
    }

    function set_isearch_text_and_value(text, value, data) {
      if (isearch_query) {
        var fire = isearch_query.value != text;
        isearch_query.value = text;
        if (fire) {
          var event = new Event("change", { "bubbles": true });
          event.isearch_data = data;
          isearch_query.dispatchEvent(event);
        }
      }
      if (isearch_value) {
        var fire = isearch_value.value != value;
        isearch_value.value = value;
        if (fire) {
          var event = new Event("change", { "bubbles": true });
          event.isearch_data = data;
          isearch_value.dispatchEvent(event);
        }
      }
      remove_child_nodes(dropdown_menu);
      dropdown_menu.classList.remove("show");
    }

    function set_placeholder() {
      if (isearch_query.hasAttribute("data-placeholder")) {
        isearch_query.setAttribute("placeholder", isearch_query.getAttribute("data-placeholder"));
        isearch_query.removeAttribute("data-placeholder");
      }
    }

    function unset_placeholder() {
      if (isearch_query.hasAttribute("placeholder")) {
        isearch_query.setAttribute("data-placeholder", isearch_query.getAttribute("placeholder"));
        isearch_query.removeAttribute("placeholder");
      }
    }

    isearch_query.addEventListener("blur", function(event) {
      window.setTimeout(function() {
        var link_to = dropdown_menu.querySelectorAll("a");
        if (link_to.length == 1) {
          var data = link_to[0].isearch_data;
          set_isearch_text_and_value(data.isearch_text, data.id, data);

        } else {
          remove_child_nodes(dropdown_menu);
          dropdown_menu.classList.remove("show");
        }

      }, 200);
    });

    isearch_query.addEventListener("keydown", function(event) {
      if (event.key == "Escape") {
        event.preventDefault();

        set_isearch_text_and_value("", init_value);
        set_placeholder();
      }

      if (event.key == "ArrowUp") {
        event.preventDefault();

        var active = dropdown_menu.querySelector(".active");
        if (active && active.previousSibling) {
          active.classList.remove("active");
          active.previousSibling.classList.add("active");
        }
      }

      if (event.key == "ArrowDown") {
        event.preventDefault();

        var active = dropdown_menu.querySelector(".active");
        if (active) {
          if (active.nextSibling) {
            active.classList.remove("active");
            active.nextSibling.classList.add("active");
          }
        } else {
          var a = dropdown_menu.querySelector("a");
          if (a) {
            a.classList.add("active");
          }
        }
      }

      if (event.key == "Enter") {
        var active = dropdown_menu.querySelector(".active");
        if (active) {
          event.preventDefault();

          var data = active.isearch_data;
          set_isearch_text_and_value(data.isearch_text, data.id, data);
        }
      }

    });

    isearch_query.addEventListener("keyup", function(event) {

      if (["ArrowDown", "ArrowUp", "Enter", "Escape"].includes(event.key)) {
        return;
      }

      var query = isearch_query.value.trim().replace(/\s/g, ' ');

      if (0 < query.length && isearch_data) {

        var filter = function(data) {
          return query.split(" ").find(function(word) {
            var r = new RegExp(word);
            return r.test(data.isearch_keyword);
          });
        }

        var list_items = isearch_data.filter(filter).slice(0, 20).map(function (data) {
          var element = document.createElement("a");
          element.classList.add("dropdown-item");
          element.text = data.isearch_text;
          element.isearch_data = data;

          return element;
        });

        remove_child_nodes(dropdown_menu);

        if (0 < list_items.length) {
          if (1 < list_items.length || (isearch_value ? list_items[0].isearch_data.id != isearch_value.value : list_items[0].isearch_data.isearch_text != isearch_query.value)) {
            set_isearch_text_and_value(isearch_query.value, "", null);

            list_items.forEach(function(list_item) {
              dropdown_menu.appendChild(list_item);
            });

            dropdown_menu.classList.add("show");
          }
        }

      } else if (query.length == 0) {
        set_isearch_text_and_value("", init_value, null);
        set_placeholder();
      }
    });

    dropdown_menu.addEventListener("click", function(event) {
      var data = event.target.isearch_data;
      set_isearch_text_and_value(data.isearch_text, data.id, data);
    });

    isearch_clear.addEventListener("click", function(event) {
      set_isearch_text_and_value("", "", null);
      unset_placeholder();
      isearch_query.focus();
    });

  });
});
