document.addEventListener("turbolinks:load", function() {
  document.querySelectorAll("form#to_mattermost").forEach(function(form) {
    add_event_listener(form, "click", "button", function(e) {
      var req = new XMLHttpRequest();

      req.open("POST", location.origin + "/nimbus/hooks/backlog/to_mattermost");
      req.setRequestHeader("Content-type", "application/json");
      req.send(document.querySelector("textarea").value);
    });
  });
});
