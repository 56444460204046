document.addEventListener("turbolinks:load", function() {

  $(document).on("change", "select[id=working_time_leave_class]", function (e) {

    if (e.currentTarget.value !== "time_paid_vacation") {

      // 開始時間を 00:00 に設定
      document.getElementById("working_time_begin_4i").value = "00";
      document.getElementById("working_time_begin_5i").value = "00";

      // 終了時間を 00:00 に設定
      document.getElementById("working_time_end_4i").value = "00";
      document.getElementById("working_time_end_5i").value = "00";

      // 休憩時間を 00:00 に設定
      document.getElementById("working_time_break_hour").value = "0";
      document.getElementById("working_time_break_min").value = "0";

    }

  });

});