export function query_parent(source, selector) {
  var target = source.parentElement;
  while (!target.matches(selector)) {
    target = target.parentElement;
  }
  return target;
}

export function remove_child_nodes(element) {
  while (element.childNodes.length > 0) {
    element.removeChild(element.firstChild);
  }
}

export function get_json(url, block) {
  var req = new XMLHttpRequest();

  req.addEventListener("load", function(e) {
    block(JSON.parse(req.responseText));
  });

  req.open("GET", url);
  req.send();
}

export function try_parse(s) {
  var o = null;

  try {
    o = JSON.parse(s);
  } catch (e) {
    o = s;
  }

  return o;
}

export function fire_event(target, event, block) {
  const e = new Event(event, { "bubbles": true });
  target.dispatchEvent(e);
}

export function set_value(target, value) {
  document.querySelectorAll(target).forEach(function(e) { e.value = value });
}

export function add_event_listener(element, event, selector, block) {
  element.addEventListener(event, function(e) {
    if (e.target.matches(selector)) {
      block(e);
      e.preventDefault();
    }
  });
}
