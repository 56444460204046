document.addEventListener("turbolinks:load", function() {
  if (document.querySelector("#search_approve_expense")) {

    // 前月ボタン
    add_event_listener(document, "click", "button[data-button=last_month]", function(e) {
      set_value("#search_approve_expense_member_id", "");
      set_value("#search_approve_expense_year", new Date().getFullYear());
      set_value("#search_approve_expense_month", new Date().getMonth());
      set_value("#search_approve_expense_is_approved", "");
    });

    // クリアボタン
    add_event_listener(document, "click", "button[data-button=reset]", function(e) {
      set_value("#search_approve_expense_member_id", "");
      set_value("#search_approve_expense_year", "");
      set_value("#search_approve_expense_month", "");
      set_value("#search_approve_expense_is_approved", "false");
    });

  }

});
